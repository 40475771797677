import { getContentfulImageProps } from 'src/cms/utils/utils';
import type {
  IActionsFields,
  IHeadingFields,
  IHeroFields,
  IImageFields,
  IMotionFields,
  ISubheadingFields,
  IVideoFields,
} from 'types/generated/contentful';
import { linkActionFields } from '../utils/linkActionFields';
import type { ActionT } from '../ui/actions/Action';
import type { CommonHeroT, FullHeroT } from '../ui/hero/Hero';
import { getMotionProps } from './utils/utils';

interface DynamicValueProps<V> {
  hasUser: boolean;
  value: V | null;
}

export const getDynamicValueDependingOnUser = <
  V extends IHeadingFields | ISubheadingFields | IActionsFields,
>({
  hasUser,
  value,
}: DynamicValueProps<V>): V['authenticated' | 'unknown'] | undefined => {
  if (!value) return;

  if (hasUser && value.authenticated) {
    return value.authenticated;
  }

  return value.unknown;
};

export function getDynamicContentfulHeading({
  hasUser,
  value,
}: {
  hasUser: boolean;
  value: IHeadingFields | null;
}): string | undefined {
  const heading = getDynamicValueDependingOnUser({ hasUser, value });
  if (heading !== null) {
    return heading;
  }
  return undefined;
}

export function getDynamicContentfulSubheading({
  hasUser,
  value,
}: {
  hasUser: boolean;
  value: ISubheadingFields | null;
}): string | undefined {
  return getDynamicValueDependingOnUser({ hasUser, value });
}

export function getDynamicContentfulReferenceActions({
  hasUser,
  value,
}: {
  hasUser: boolean;
  value: IActionsFields | null;
}): Array<ActionT> | undefined {
  const actions = getDynamicValueDependingOnUser({ hasUser, value });

  if (!actions) return;

  return actions
    .map(({ fields }) => linkActionFields(fields))
    .filter(Boolean) as ActionT[];
}

export function getDynamicContentfulHero({
  hasUser,
  hero,
}: {
  hasUser: boolean;
  hero: IHeroFields | null;
}): CommonHeroT | FullHeroT {
  return {
    badge: hero?.badge?.fields.text,
    heading: getDynamicContentfulHeading({
      hasUser,
      value: hero?.heading?.fields ?? null,
    }),
    subheading: getDynamicContentfulSubheading({
      hasUser,
      value: hero?.subheading?.fields ?? null,
    }),
    actions: getDynamicContentfulReferenceActions({
      hasUser,
      value: hero?.actions?.fields ?? null,
    }),
    image: (hero?.image?.fields as IImageFields)?.image?.fields.file
      ? getContentfulImageProps(hero?.image?.fields as IImageFields)
      : undefined,
    motion: (hero?.image?.fields as IMotionFields)?.motion?.fields.file
      ? getMotionProps(hero?.image?.fields as IMotionFields)
      : undefined,
    video: (hero?.image?.fields as IVideoFields)?.url
      ? (hero?.image?.fields as IVideoFields)
      : undefined,
    layout: hero?.layout,
    invertLayout: hero?.invertLayout,
    imageMaxWidth: hero?.imageMaxWidth,
    theme: hero?.mood?.fields.colors
      ? { colors: hero?.mood?.fields.colors }
      : undefined,
  };
}
