import type { Options } from '@contentful/rich-text-react-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import { BLOCKS } from '@contentful/rich-text-types';
import CarouselStatement from 'src/ui/blocks/carousel/CarouselStatement';
import { linkActionFields } from 'src/utils/linkActionFields';
import type { IBlockStatement } from 'types/generated/contentful';
import { getContentfulImageProps } from './utils/utils';

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: () => null,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const id = node.data.target?.sys.contentType?.sys.id;

      if (id === 'blockStatement') {
        const { fields } = node.data.target as IBlockStatement;
        return (
          <CarouselStatement
            action={linkActionFields(fields?.action?.fields)}
            attribution={fields?.attribution}
            statement={fields?.statement ?? ''} // draft in Contentful
            image={
              fields?.image?.fields?.image?.fields
                ? getContentfulImageProps(fields.image.fields)
                : undefined
            }
          />
        );
      }

      return null;
    },
  },
};

interface Props {
  document: Document;
}

export function CarouselContentRenderer({ document }: Props): any {
  return documentToReactComponents(
    {
      ...document,
      content: document.content.map((x) => ({
        ...x,
        id: x.data?.target?.sys.contentType?.sys.id,
      })),
    },
    options,
  );
}
