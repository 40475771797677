import * as React from 'react';
import type { UserResponseT } from '@mentimeter/http-clients';
import { AppStateConsumer } from '../appState';

interface AuthenticatedT {
  children: React.ReactNode | ((user: UserResponseT) => React.ReactNode);
}

interface UnknownT {
  children: React.ReactNode;
}

class User extends React.Component<{
  children: (user: UserResponseT | null) => React.ReactNode;
}> {
  static Authenticated = ({ children }: AuthenticatedT) => (
    <AppStateConsumer>
      {(appState) =>
        appState !== null && appState.user !== null
          ? typeof children === 'function'
            ? children(appState.user)
            : children
          : null
      }
    </AppStateConsumer>
  );

  static Unknown = ({ children }: UnknownT) => (
    <AppStateConsumer>
      {(appState) => (appState?.user === null ? children : null)}
    </AppStateConsumer>
  );

  static Loading = ({ children }: UnknownT) => (
    <AppStateConsumer>
      {(appState) =>
        !appState?.user && !appState?.userFinishedLoading ? children : null
      }
    </AppStateConsumer>
  );

  static NotExisting = ({ children }: UnknownT) => (
    <AppStateConsumer>
      {(appState) =>
        !appState?.user && appState?.userFinishedLoading ? children : null
      }
    </AppStateConsumer>
  );

  override render() {
    const { children } = this.props;
    return (
      <AppStateConsumer>
        {(appState) => children(appState?.user || null)}
      </AppStateConsumer>
    );
  }
}

export default User;
