import { BLOCKS, type Document } from '@contentful/rich-text-types';
import { VisitorExperiment } from 'src/components/experiments/VisitorExperiment';
import type { ISplitExperimentFields } from 'types/generated/contentful';
import { PageRenderer } from '../PageRenderer';

const checkEmbeddedTypes = (contentfulDocument: Document) =>
  contentfulDocument?.content?.some(
    ({ nodeType }) => nodeType === BLOCKS.EMBEDDED_ENTRY,
  );

const ContentfulSplitExperiment = ({
  splitId,
  v1,
  v2,
  v3,
  experimentIsLive,
}: ISplitExperimentFields) => {
  const v1IsValid = checkEmbeddedTypes(v1);
  if (!v1IsValid) return null;

  const v2IsValid = checkEmbeddedTypes(v2);
  if (!experimentIsLive || !v2IsValid) return <PageRenderer document={v1} />;

  const variants = {
    // eslint-disable-next-line react/no-unstable-nested-components -- Do not define components during render.
    control: () => <PageRenderer document={v1} />,
    // eslint-disable-next-line react/no-unstable-nested-components -- Do not define components during render.
    v1: () => <PageRenderer document={v1} />,
    // eslint-disable-next-line react/no-unstable-nested-components -- Do not define components during render.
    v2: () => <PageRenderer document={v2} />,
    // eslint-disable-next-line react/no-unstable-nested-components -- Do not define components during render.
    v3: () => v3 && <PageRenderer document={v3} />,
  };

  return <VisitorExperiment splitId={splitId as any} variants={variants} />;
};

export default ContentfulSplitExperiment;
