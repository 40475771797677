import { gtmTrack } from '@mentimeter/google-tracking';
import type { BoxT } from '@mentimeter/ragnar-ui';
import { Box } from '@mentimeter/ragnar-ui';
import React, { useEffect, useRef, useState } from 'react';
import type { visitorExperiments } from 'src/split-experiments';
import { useVisitorSplits } from 'src/split-hooks';

interface Props {
  splitId: keyof typeof visitorExperiments;
  variants: {
    control: React.ElementType;
    not_ready?: React.ElementType;
    [key: string]: React.ElementType;
  };
  placeHolderProps?: BoxT;
  sendTrackingData?: boolean;
}

export const VisitorExperiment = ({
  lazyLoad,
  ...experimentProps
}: Props & { lazyLoad?: boolean }) => {
  const [showExperiment, setShowExperiment] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const intersectionIsSupported =
    typeof window !== 'undefined' && 'IntersectionObserver' in window;

  useEffect(() => {
    if (lazyLoad && intersectionIsSupported) {
      const observer = new IntersectionObserver(
        (entries) => {
          const [container] = entries;
          if (!showExperiment && container) {
            setShowExperiment(container.isIntersecting);
          }
        },
        {
          rootMargin: '350px',
        },
      );
      const container = containerRef.current;
      if (container) observer.observe(container);

      return () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        container && observer.unobserve(container);
      };
    }
    return;
  }, [lazyLoad, containerRef, showExperiment, intersectionIsSupported]);

  if (!lazyLoad || !intersectionIsSupported)
    return <VisitorExperimentInner {...experimentProps} />;

  return (
    <div ref={containerRef}>
      {showExperiment ? <VisitorExperimentInner {...experimentProps} /> : null}
    </div>
  );
};

export const VisitorExperimentInner = ({
  splitId,
  variants,
  sendTrackingData = false,
  ...rest
}: Props) => {
  const { [splitId]: treatment } = useVisitorSplits([splitId]);
  useEffect(() => {
    if (
      sendTrackingData &&
      splitId &&
      !(treatment === 'not_ready' || treatment === 'control')
    ) {
      gtmTrack({
        event: 'experiment',
        type: `${splitId}-${treatment}`,
      });
    }
  }, [sendTrackingData, treatment, splitId]);

  const { control: ControlComponent, not_ready: NotReadyComponent } = variants;

  if (treatment === 'not_ready') {
    return NotReadyComponent ? (
      <NotReadyComponent />
    ) : (
      <Box
        extend={() => ({
          visibility: 'hidden',
        })}
        {...rest}
      >
        <ControlComponent />
      </Box>
    );
  }

  const TreatmentComponent = variants[treatment];
  if (TreatmentComponent) return <TreatmentComponent />;

  return <ControlComponent />;
};
