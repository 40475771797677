const defaultDateTimeFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat(
  'en-US',
  {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
    timeZoneName: 'short',
  },
);

interface FormatDateT {
  date: string;
  dateTimeFormat?: Intl.DateTimeFormat;
}

export const formatDate = ({
  date,
  dateTimeFormat = defaultDateTimeFormat,
}: FormatDateT) => dateTimeFormat.format(new Date(date));
